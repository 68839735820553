.__error {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 100%;
  height: 100vh;
  background-color: #134e5e21;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.login_card {
  width: 400px;
  min-height: 300px;
  border-radius: 10px;
}

.login__password {
  margin-top: 15px;
}

.login__button {
  margin-top: 15px;
}

.login__logo {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .login_card {
    width: 100%;
  }
}

.logo {
  height: 80px;
  margin: 16px;
  padding: 10px;
}

.logo img {
  object-fit: contain;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.no-image {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin: 5px;
}

.btns__list {
  display: flex;
  align-items: center;
  justify-content: end;
}

.page-header {
  display: flex;
  flex-wrap: wrap;
}

.input__margin {
  margin-right: 10px;
  margin-top: 10px;
}

.spinner {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: white;
  opacity: 0.6;
}

.account__form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form_wrapper {
  width: 90%;
}

.prestataire {
  display: flex;
  align-items: center;
  gap: 5px;
}

.prestataire .status {
  margin-bottom: 12px;
  width: 15px;
  height: 15px;
  border-radius: 100vmax;
}

.prestataire .VALID.ACCOUNT {
  background: rgb(30, 210, 30);
}
.prestataire .NOT.COMPLETED {
  background: rgb(231, 66, 66);
}
.prestataire .EN.ATTENTE {
  background: rgb(252, 240, 20);
}